<template>
  <v-row>
    <v-col cols="12">
      <report-list></report-list>
    </v-col>
  </v-row>
</template>

<script>
import ReportList from './components/ReportList.vue'

export default {
  components: {
    ReportList,
  },
  setup() {
    return {}
  },
}
</script>
