<template>
  <div>
    <p class="headline black--text font-weight-bold">Laporan Campaign</p>
    <div v-if="isLoading">
      <div class="d-flex justify-center">
        <v-skeleton-loader
            class="mx-auto pa-1"
            width="1200"
            type="card"
          ></v-skeleton-loader>
      </div>
      <br>
      <div class="d-flex justify-center">
        <v-skeleton-loader
            class="mx-auto pa-1"
            width="1200"
            type="card"
          ></v-skeleton-loader>
      </div>
    </div>
    <div v-else>
      <v-row class="mt-0" no-gutters  v-if="itemData.length > 0">
        <div class="col-md-12 col-lg-12">
          <v-card class="overflow-hidden elevation-1 mb-5"  v-for="list in itemData" :key="list.cartId">
            <!-- DESKTOP -->
            <v-card-text v-if="!isMobile()">
              <div class="row">
                <div class="col-md-6">
                  <p class="mb-0 font-weight-bold">{{ list.order.invoice }}</p>
                </div>
                <div class="col-md-6">
                  <div class="d-flex justify-end">
                    <p class="mb-0">{{list.order.date_added | dateFormat2}}</p>
                  </div>
                </div>
              </div>
              <v-divider class="mb-3 mt-2"></v-divider>
              <div class="row">
                <div class="col-md-2">
                  <p class="d-flex align-center mb-0">Nama Paket</p>
                </div>
                <div class="col-md-2">
                  <p class="d-flex align-center mb-0">Jenis Paket</p>
                </div>
                <div class="col-md-4">
                  <p class="d-flex align-center mb-0">Format Konten</p>
                </div>
                <div class="col-md-2">
                  <p class="d-flex align-center mb-0">Level Influencer</p>
                </div>
                <div class="col-md-2">
                  <p class="d-flex align-center mb-0">Jumlah Influencer</p>
                </div>
                <!-- <div class="col-md-2">
                  <p class="d-flex align-center mb-0">Total Followers</p>
                </div> -->
              </div>
              <div class="row mt-0"  v-for="listPackage in list.order_detail" :key="listPackage.id">
                <div class="col-md-2">
                  <p class="d-flex align-center mb-0">
                    <span class="text-capitalize font-weight-bold body-1 black--text">{{ listPackage.packages.name }}</span>
                  </p>
                </div>
                <div class="col-md-2">
                  <p class="d-flex align-center mb-0">
                    <span class="text-capitalize font-weight-bold body-1 black--text">{{ listPackage.packages.description | labelFormat }}</span>
                  </p>
                </div>
                <div class="col-md-4">
                  <p class="d-flex align-center mb-0">
                    <span class="text-capitalize font-weight-bold body-1 black--text">{{ listPackage.packages.content }}</span>
                  </p>
                </div>
                <div class="col-md-2 d-flex align-center">
                  <p class=" mb-0 text-capitalize font-weight-bold body-1 black--text">{{ listPackage.packages.level | levelFormat}}
                  </p>
                </div>
                <div class="col-md-2 d-flex align-center">
                  <p class=" mb-0">
                    <span class="text-capitalize font-weight-bold body-1 black--text">{{ listPackage.packages.qty }}</span>
                  </p>
                </div>
                <!-- <div class="col-md-2">
                  <p class="d-flex align-center mb-0">
                    <span class="text-capitalize font-weight-bold body-1 black--text">Rp {{ listPackage.packages.price | numberFormat }}</span>
                  </p>
                </div> -->
              </div>
              <!-- <v-divider class="mb-3 mt-1"></v-divider> -->
              <div class="row">
                <div class="col-md-6">
                  <v-btn @click="goToDetail(list.order.id)" class="text-capitalize" color="primary">Lihat Detail</v-btn>
                </div>
                <!-- <div class="col-md-6">
                  <p class="mb-0 font-weight-bold"  style="float: right" >Total Harga <span class="secondary--text font-weight-bold  body-1 ml-1">Rp {{ list.order.total_plus_code | numberFormat}}</span></p>
                </div> -->
              </div>
            </v-card-text>
            <!-- MOBILE -->
            <v-card-text v-else>
              <div class="row">
                <div class="col-sm-12">
                  <p class="mb-0 font-weight-bold">INVOICE <span class="font-weight-medium">{{ list.order.invoice }}</span></p>
                  <p class="font-weight-bold mb-0"><span class="caption">{{ list.order.date_added | dateFormat2 }}</span></p>
                </div>
              </div>
              <v-divider class="mb-3 mt-2"></v-divider>
              <div class="row"  v-for="listPackage in list.order_detail" :key="listPackage.id">
                <div class="col-md-2 mt-0 mb-0 pt-2 pb-0">
                  <p class="d-flex align-center mb-0">Nama Paket</p>
                  <p class="d-flex align-center mb-0">
                    <span class="text-capitalize font-weight-bold black--text">{{ listPackage.packages.name }}</span>
                  </p>
                </div>
                <div class="col-md-2 mt-0 mb-0 pt-2 pb-0">
                  <p class="d-flex align-center mb-0">Jenis Paket</p>
                  <p class="d-flex align-center mb-0">
                    <span class="text-capitalize font-weight-bold black--text">{{ listPackage.packages.description | labelFormat}}</span>
                  </p>
                </div>
                <div class="col-md-4 mt-0 mb-0 pt-2 pb-0">
                  <p class="d-flex align-center mb-0">Format Konten</p>
                  <p class="d-flex align-center mb-0">
                    <span class="text-capitalize font-weight-bold black--text">{{ listPackage.packages.content | labelFormat}}</span>
                  </p>
                </div>
                <div class="col-md-2 mt-0 mb-0 pt-2 pb-0">
                  <p class="d-flex align-center mb-0">Level Influencer</p>
                  <p class=" mb-0 text-capitalize font-weight-bold black--text">{{ listPackage.packages.level | levelFormat}}
                  </p>
                </div>
                <div class="col-md-2 mt-0 mb-0 pt-2 pb-0">
                  <p class="d-flex align-center mb-0">Total Influencer</p>
                  <p class=" mb-0">
                    <span class="text-capitalize font-weight-bold body-1 black--text">{{ listPackage.packages.qty }}</span>
                  </p>
                  <v-divider class="mb-1 mt-3"></v-divider>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <v-btn block @click="goToDetail(list.order.id)" class="text-capitalize" color="primary">Lihat Detail</v-btn>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-row>
      <div v-else>
        <div class="d-flex justify-center mt-5">
          <v-card width="600">
            <v-card-title class="text-center">Oops! Laporan Campaign Anda Belum Tersedia</v-card-title>
            <v-card-text><p>Laporan campaign Anda akan ditampilkan disini secara real time. Silahkan buat campaign untuk mendapatkan laporan detail progress hingga hasil campaign Anda.</p></v-card-text>
            <v-card-actions class="d-flex justify-center mt-0">
              <v-btn @click="goStarted" class="text-capitalize" color="primary">Buat Campaign Sekarang</v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import axios from 'axios'

export default defineComponent({
  components: {
  },
  setup() {
    return{
      totalPrice:0,
      itemData:[],
      isLoading:false,
      total:0,
      serviceFee:0,
    }
  },
  mounted(){
    this.loadOrders()
  },
  methods:{
    encryptData(val) {
      let secretKey = 'U2FsdGVkX19Om4wikNyowykfw0xVZpvjyfZbNH/t5xg='
      return this.$CryptoJS.AES.encrypt(val.toString(), secretKey).toString();
    },
    loadOrders(){
      this.isLoading = true;
      let token = localStorage.getItem('token')
      axios.get(
        process.env.VUE_APP_API_ENDPOINT + 'requestCampaign/getPaidOrders', {headers: {
          'Authorization': 'Bearer '+ token
        }}).then(response => {
          if(response.status === 200){
            // console.log(response)
            this.itemData = response.data.data
            for(let a = 0; a < this.itemData.length; a++){
              this.itemData[a].order.date_added = new Date(this.itemData[a].order.date_added)
              this.itemData[a].order.due_date = new Date(this.itemData[a].order.due_date)
              for(let b=0; b < this.itemData[a].order_detail.length; b++){
                if(this.itemData[a].order_detail[b].type === 'custom'){
                  this.itemData[a].order_detail[b].packages.description = this.itemData[a].order_detail[b].packages.type
                  this.itemData[a].order_detail[b].packages.type = this.itemData[a].order_detail[b].packages.level
                  this.itemData[a].order_detail[b].packages.content = this.itemData[a].order_detail[b].packages.format
                  this.itemData[a].order_detail[b].packages.name = this.itemData[a].order_detail[b].packages.name.slice(0,6)
                }
              }
            }
            setTimeout(() => { 
              this.isLoading = false
            }, 500);
          }
        }).catch(error => {
          console.log(error)
      });
    },
    goToDetail(id){
      this.$router.push({name:'report-detail', query: {order: this.encryptData(id)}});
    },
    goStarted(){
      this.$router.push({name:'campaign'}); 
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
  filters: {
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    },
    labelFormat (value) {
      return value.replace(/-/g,' ');
    },
    dateFormat(value){
      return value.toString().slice(0, 24)
    },
    dateFormat2(value){
      return value.toString().slice(0, 16)
    },
    levelFormat(value){
      if(value === 'A'){
        return 'Nano'
      } else {
        return 'Micro'
      }
    }
  },
})
</script>